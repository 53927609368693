import React, { useEffect, useState } from "react";
import {
  EuiFlexGrid,
  EuiFlexGroup,
  EuiFlexItem,
  EuiPanel,
  EuiSpacer,
  EuiText,
  EuiTitle,
  EuiLoadingSpinner,
  EuiAvatar,
  EuiButton,
  EuiButtonIcon,
  EuiSelectable,
  EuiPopover,
  EuiPopoverTitle,
  EuiPopoverFooter,
  EuiTextColor,
  EuiIcon,
  EuiStat,
  EuiFormRow,
  EuiSwitch,
  EuiContextMenu,
  EuiEmptyPrompt,
} from "@elastic/eui";
import { MdCall } from "react-icons/md";
import RealtimeService from "../services/realtime-service";
import { ThreeDots } from "react-loader-spinner";
import { FullScreen, useFullScreenHandle } from "react-full-screen";
import { FaHeadphones, FaMicrophone, FaMicrophoneSlash } from "react-icons/fa";
import BauhausService from "../services/bauhaus-service";
import Notification from "./Notification";
import AgentStatus from "./Timer";

const Realtime = () => {
  const [queues, setQueues] = useState([]);
  const [agents, setAgents] = useState([]);
  const [loading, setLoading] = useState(true);
  const [accessPannelErrored, setAccessPanelErrored] = useState(true);
  const handle = useFullScreenHandle();
  const [isPopoverOpenTags, setIsPopoverOpenTags] = useState(false);

  const [globalMetrics, setGlobalMetrics] = useState({});
  const [showGlobalMetrics, setShowGlobalMetrics] = useState(true);
  const [showQueueTags, setQueuetags] = useState(false);

  const [options, setOptions] = useState([]);
  const [filters, setFilters] = useState([]);

  const [isPopoverOpen, setIsPopoverOpen] = useState({});
  const [loadingSpyCall, setLoadingSpyCall] = useState({});

  const [toasts, setToasts] = useState([]); // Estado para manejar las notificaciones

  const togglePopover = (agentName) => {
    setIsPopoverOpen((prevState) => ({
      ...prevState,
      [agentName]: !prevState[agentName],
    }));
  };

  const closePopover = (agentName) => {
    setIsPopoverOpen((prevState) => ({
      ...prevState,
      [agentName]: false,
    }));
  };

  const onChange = (e) => {
    setOptions(e);
  };

  const statusColors = {
    Unknown: "#d3d3d3",
    Available: "#00bfb3",
    AfterCallWork: "purple",
    Busy: "#bd271e",
    "In Call": "#bd271e",
    Invalid: "#d3d3d3",
    Unavailable: "#d3d3d3",
    Ringing: "#9C7E1C",
    "On hold": "#9C7E1C",
  };

  useEffect(() => {
    const token = sessionStorage.getItem("krakenT");

    const fetchQueues = async () => {
      try {
        const response = await RealtimeService.realtime(token);
        const queues = response.data.message?.queues;
        setGlobalMetrics(response.data.message?.globalMetrics);

        const optionsToPush = [];
        const agentsMap = new Map();

        queues.forEach((queue) => {
          optionsToPush.push({
            label: queue.queue.slice(5),
            prepend: (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill={queue.color}
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                style={{
                  width: "16px",
                  height: "16px",
                }}
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M9.568 3H5.25A2.25 2.25 0 0 0 3 5.25v4.318c0 .597.237 1.17.659 1.591l9.581 9.581c.699.699 1.78.872 2.607.33a18.095 18.095 0 0 0 5.223-5.223c.542-.827.369-1.908-.33-2.607L11.16 3.66A2.25 2.25 0 0 0 9.568 3Z"
                />
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M6 6h.008v.008H6V6Z"
                />
              </svg>
            ),
          });

          queue.agents.forEach((agent) => {
            if (!agentsMap.has(agent.name)) {
              const callStartTime = agent.callStartTime
                ? new Date(agent.callStartTime * 1000)
                : null;
              const elapsedTime = callStartTime
                ? Math.floor((Date.now() - callStartTime) / 1000)
                : 0;

              agentsMap.set(agent.name, {
                ...agent,
                queues: [{ name: queue.queue, color: queue.color }],
                callStartTime,
                elapsedTime,
              });
            } else {
              agentsMap.get(agent.name).queues.push({
                name: queue.queue,
                color: queue.color,
              });
            }
          });
        });

        setOptions(optionsToPush);
        setQueues(queues);
        setAgents((prevAgents) => {
          const updatedAgents = Array.from(agentsMap.values()).map((agent) => {
            const prevAgent = prevAgents.find((a) => a.name === agent.name);
            if (prevAgent && prevAgent.inCall && agent.inCall) {
              return { ...agent, elapsedTime: prevAgent.elapsedTime };
            }
            return agent;
          });

          // Ordenar primero por los Available, luego otros estados, y por último los que tienen paused = "1"
          updatedAgents.sort((a, b) => {
            // Prioridad de los estados
            const statePriority = {
              Available: 1,
              AfterCallWork: 2,
              Ringing: 3,
              Paused: 4, // Prioridad para paused = "1"
              default: 5, // Cualquier otro estado
            };

            // Determinar la prioridad para los estados
            const aPriority =
              a.paused === "1"
                ? statePriority.Paused
                : statePriority[a.status] || statePriority.default;
            const bPriority =
              b.paused === "1"
                ? statePriority.Paused
                : statePriority[b.status] || statePriority.default;

            // Ordenar por prioridad del estado
            if (aPriority !== bPriority) {
              return aPriority - bPriority;
            }

            // Si están en el mismo grupo, ordenar por statusStartTime
            return new Date(a.statusStartTime) - new Date(b.statusStartTime);
          });
          return updatedAgents;
        });

        setLoading(false);
        setAccessPanelErrored(false);
      } catch (error) {
        setAccessPanelErrored(true);
        setLoading(false);
      }
    };

    fetchQueues(filters);
  }, []);

  useEffect(() => {
    const token = sessionStorage.getItem("krakenT");
    const refreshRealtime = async (filteredQueues = []) => {
      try {
        const response = await RealtimeService.realtime(token, filteredQueues);
        const queues = response.data.message?.queues;
        setGlobalMetrics(response.data.message?.globalMetrics);

        const agentsMap = new Map();

        queues.forEach((queue) => {
          queue.agents.forEach((agent) => {
            if (!agentsMap.has(agent.name)) {
              const callStartTime = agent.callStartTime
                ? new Date(agent.callStartTime * 1000)
                : null;
              const elapsedTime = callStartTime
                ? Math.floor((Date.now() - callStartTime.getTime()) / 1000)
                : 0;

              agentsMap.set(agent.name, {
                ...agent,
                queues: [{ name: queue.queue, color: queue.color }],
                callStartTime,
                elapsedTime,
              });
            } else {
              agentsMap.get(agent.name).queues.push({
                name: queue.queue,
                color: queue.color,
              });
            }
          });
        });
        setQueues(queues);
        setAgents((prevAgents) => {
          const updatedAgents = Array.from(agentsMap.values()).map((agent) => {
            const prevAgent = prevAgents.find((a) => a.name === agent.name);
            if (prevAgent && prevAgent.inCall && agent.inCall) {
              return { ...agent, elapsedTime: prevAgent.elapsedTime };
            }
            return agent;
          });
          setLoading(false);
          updatedAgents.sort((a, b) => {
            // Prioridad de los estados
            const statePriority = {
              Available: 1,
              AfterCallWork: 2,
              Ringing: 3,
              Paused: 4, // Prioridad para paused = "1"
              default: 5, // Cualquier otro estado
            };

            // Determinar la prioridad para los estados
            const aPriority =
              a.paused === "1"
                ? statePriority.Paused
                : statePriority[a.status] || statePriority.default;
            const bPriority =
              b.paused === "1"
                ? statePriority.Paused
                : statePriority[b.status] || statePriority.default;

            // Ordenar por prioridad del estado
            if (aPriority !== bPriority) {
              return aPriority - bPriority;
            }

            // Si están en el mismo grupo, ordenar por statusStartTime
            return new Date(a.statusStartTime) - new Date(b.statusStartTime);
          });
          return updatedAgents;
        });
      } catch (error) {
        setLoading(false);
      }
    };
    const intervalId = setInterval(() => refreshRealtime(filters), 1500);
    return () => clearInterval(intervalId);
  }, [filters]);
  const applyFilters = () => {
    const filteredQueues = options
      .filter((option) => option.checked === "on")
      .map((option) => option.label);
    setFilters(filteredQueues);
    setLoading(true);
    setIsPopoverOpenTags(false);
  };

  // useEffect(() => {
  //   if (!accessPannelErrored) {
  //     const intervalId = setInterval(() => {
  //       setAgents((prevAgents) =>
  //         prevAgents.map((agent) => {
  //           if (agent.callStartTime) {
  //             return {
  //               ...agent,
  //               elapsedTime: agent.elapsedTime + 1,
  //             };
  //           }
  //           return agent;
  //         })
  //       );
  //     }, 1000);

  //     return () => clearInterval(intervalId);
  //   }
  // }, []);

  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${String(minutes).padStart(2, "0")}:${String(
      remainingSeconds
    ).padStart(2, "0")}`;
  };
  const onChangeShowGlobalMetrics = (e) => {
    setShowGlobalMetrics(e.target.checked);
  };
  const onChangeShowQueueTags = (e) => {
    setQueuetags(e.target.checked);
  };

  const handleSpyCall = async (agent, spyType) => {
    const token = sessionStorage.getItem("krakenT");

    setLoadingSpyCall((prevState) => ({
      ...prevState,
      [agent.name]: true,
    }));

    try {
      const data = {
        targetExtension: agent.name,
        spyType,
        displayName: `${spyType === "active" ? "Active" : "Passive"} - ${
          agent.name
        }`,
      };
      await BauhausService.spy_call({ token, data });
      setToasts((prevToasts) => [
        ...prevToasts,
        {
          id: `${agent.name}-${spyType}`,
          title: "Escucha realizada con éxito",
          color: "success",
          iconType: "check",
        },
      ]);
      closePopover(agent.name);
    } catch (error) {
      console.error("Error en la escucha:", error);
      setToasts((prevToasts) => [
        ...prevToasts,
        {
          id: `${agent.name}-${spyType}-error`,
          title: "Error en la escucha",
          color: "danger",
          iconType: "alert",
          text: "Ud. no tiene permisos para realizar esta acción",
        },
      ]);
    } finally {
      setLoadingSpyCall((prevState) => ({
        ...prevState,
        [agent.name]: false,
      }));
    }
  };

  return (
    <div
      style={{
        height: "100%",
        width: "100%",
        padding: "8px",
      }}
    >
      {accessPannelErrored && !loading ? (
        <EuiEmptyPrompt
          iconType="alert"
          color="danger"
          title={<h2>Unable to load Realtime Dashboard</h2>}
          body={
            <p>
              There was an error loading the Realtime Dashboard application.
              Contact your administrator for help support@astroline.com.
            </p>
          }
        />
      ) : (
        <>
          <Notification toasts={toasts} setToasts={setToasts} />
          <EuiFlexGroup alignItems="center">
            <EuiFlexItem grow={true}>
              <EuiPopover
                panelPaddingSize="none"
                button={
                  <EuiButton
                    color="primary"
                    fill
                    iconType="arrowDown"
                    iconSide="right"
                    onClick={() => setIsPopoverOpenTags(!isPopoverOpenTags)}
                  >
                    Filter Queues
                  </EuiButton>
                }
                isOpen={isPopoverOpenTags}
                closePopover={() => setIsPopoverOpenTags(false)}
              >
                <EuiSelectable
                  singleSelection={false}
                  searchable
                  searchProps={{
                    placeholder: "Search clients",
                    compressed: true,
                  }}
                  options={options}
                  onChange={(newOptions) => setOptions(newOptions)}
                >
                  {(list, search) => (
                    <div style={{ width: 240 }}>
                      <EuiPopoverTitle paddingSize="s">
                        {search}
                      </EuiPopoverTitle>
                      {list}
                      <EuiPopoverFooter paddingSize="s">
                        <EuiButton size="s" fullWidth onClick={applyFilters}>
                          Apply Filter{" "}
                        </EuiButton>
                      </EuiPopoverFooter>
                    </div>
                  )}
                </EuiSelectable>
              </EuiPopover>
            </EuiFlexItem>
            <EuiFlexItem grow={false}>
              <EuiFormRow
                display="columnCompressedSwitch"
                label="Global Metrics"
              >
                <EuiSwitch
                  checked={showGlobalMetrics}
                  onChange={onChangeShowGlobalMetrics}
                  compressed
                />
              </EuiFormRow>
            </EuiFlexItem>
            <EuiFlexItem
              grow={false}
              alignItems={"center"}
              justifyContent={"center"}
            >
              <EuiFormRow display="columnCompressedSwitch" label="Queue Tags">
                <EuiSwitch
                  checked={showQueueTags}
                  onChange={onChangeShowQueueTags}
                  compressed
                />
              </EuiFormRow>
            </EuiFlexItem>
            <EuiFlexItem grow={false}>
              <EuiButtonIcon
                aria-labelledby="tes"
                size="m"
                onClick={handle.enter}
                iconType={"fullScreen"}
              ></EuiButtonIcon>
            </EuiFlexItem>
          </EuiFlexGroup>
          <EuiSpacer></EuiSpacer>
          {loading ? (
            <div
              style={{
                height: "100%",
                width: "100%",
                padding: "8px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <EuiLoadingSpinner size="xl" />
            </div>
          ) : (
            <FullScreen handle={handle}>
              <div
                style={{ background: "#f7f8fc", width: "100%", height: "100%" }}
                tabIndex={0}
                className="eui-scrollBar"
              >
                {showGlobalMetrics && (
                  <div>
                    {/* Modificación en el primer panel para añadir "Service Level" */}
                    <EuiFlexGroup>
                      <EuiFlexItem grow={3}>
                        <EuiPanel>
                          <EuiFlexGroup>
                            <EuiFlexItem>
                              <EuiStat
                                title={
                                  <span style={{ fontSize: "1.4em" }}>
                                    {Math.round(
                                      globalMetrics.attended_calls_percentage
                                    )}
                                    %
                                  </span>
                                }
                                textAlign="left"
                                isLoading={loading}
                                titleColor="success"
                                description={
                                  <EuiTextColor color="success">
                                    <span style={{ fontSize: "1em" }}>
                                      {globalMetrics.attended_calls}
                                    </span>
                                  </EuiTextColor>
                                }
                              >
                                <span style={{ fontSize: "1em" }}>
                                  Attended Calls
                                </span>
                              </EuiStat>
                            </EuiFlexItem>
                            <EuiFlexItem>
                              <EuiStat
                                title={
                                  <span style={{ fontSize: "1.4em" }}>
                                    {Math.round(
                                      globalMetrics.non_attended_calls_percentage
                                    )}
                                    %
                                  </span>
                                }
                                textAlign="left"
                                isLoading={loading}
                                titleColor="danger"
                                description={
                                  <EuiTextColor color="danger">
                                    <span style={{ fontSize: "1em" }}>
                                      {Math.round(
                                        globalMetrics.non_attended_calls
                                      )}
                                    </span>
                                  </EuiTextColor>
                                }
                              >
                                <span style={{ fontSize: "1em" }}>
                                  Non-Attended Calls
                                </span>
                              </EuiStat>
                            </EuiFlexItem>
                            <EuiFlexItem>
                              <EuiStat
                                title={
                                  <span style={{ fontSize: "1.4em" }}>
                                    {Math.round(globalMetrics.service_level)}%
                                  </span>
                                }
                                textAlign="left"
                                isLoading={loading}
                                titleColor="primary"
                                description={
                                  <EuiTextColor color="primary">
                                    <span style={{ fontSize: "1em" }}>
                                      Before 45 seconds
                                    </span>
                                  </EuiTextColor>
                                }
                              >
                                <span style={{ fontSize: "1em" }}>
                                  Service Level
                                </span>
                              </EuiStat>
                            </EuiFlexItem>
                            <EuiFlexItem>
                              <EuiFlexGroup direction="column" gutterSize="s">
                                <EuiFlexItem>
                                  <EuiText size="xs" textAlign="center">
                                    Attempted Transfers
                                  </EuiText>
                                  <EuiText
                                    style={{
                                      fontSize: 25,
                                    }}
                                    textAlign="center"
                                    color="warning"
                                  >
                                    <strong>
                                      {Math.round(
                                        globalMetrics.percentage_attempted_transfers
                                      )}
                                      %
                                    </strong>{" "}
                                    ({globalMetrics.attempted_transfers})
                                  </EuiText>
                                </EuiFlexItem>
                                <EuiFlexItem>
                                  <EuiText size="xs" textAlign="center">
                                    Completed Transfers
                                  </EuiText>
                                  <EuiText
                                    style={{
                                      fontSize: 25,
                                    }}
                                    textAlign="center"
                                    color="success"
                                  >
                                    <strong>
                                      {Math.round(
                                        globalMetrics.percentage_completed_transfers
                                      )}
                                      %
                                    </strong>{" "}
                                    ({globalMetrics.completed_transfers})
                                  </EuiText>
                                </EuiFlexItem>
                              </EuiFlexGroup>
                            </EuiFlexItem>
                            {/* Nuevo KPI: Completed Transfers */}
                          </EuiFlexGroup>
                        </EuiPanel>
                      </EuiFlexItem>
                      <EuiFlexItem grow={1}>
                        <EuiPanel>
                          <EuiStat
                            title={
                              <span style={{ fontSize: "1.4em" }}>
                                {globalMetrics.calls_on_hold}
                              </span>
                            }
                            textAlign="center"
                            isLoading={loading}
                            titleColor="warning"
                            description={
                              <span style={{ fontSize: "1em" }}>
                                Queued Calls
                              </span>
                            }
                          />
                        </EuiPanel>
                      </EuiFlexItem>
                      <EuiFlexItem grow={3}>
                        <EuiPanel>
                          <EuiFlexGroup>
                            <EuiFlexItem>
                              <EuiStat
                                title={
                                  <span style={{ fontSize: "1.4em" }}>
                                    {formatTime(globalMetrics?.talk_time)}
                                  </span>
                                }
                                textAlign="left"
                                isLoading={loading}
                                titleColor="accent"
                                description={
                                  <span style={{ fontSize: "1em" }}>AHT</span>
                                }
                              >
                                <EuiTextColor
                                  color="accent"
                                  style={{ fontSize: "1em" }}
                                >
                                  Average Handle Time
                                </EuiTextColor>
                              </EuiStat>
                            </EuiFlexItem>
                            <EuiFlexItem>
                              <EuiStat
                                title={
                                  <span style={{ fontSize: "1.4em" }}>
                                    {formatTime(globalMetrics?.hold_time)}
                                  </span>
                                }
                                description={
                                  <span style={{ fontSize: "1em" }}>HT</span>
                                }
                                textAlign="left"
                                titleColor="accent"
                                isLoading={loading}
                              >
                                <EuiTextColor
                                  color="accent"
                                  style={{ fontSize: "1em" }}
                                >
                                  Ringing Time
                                </EuiTextColor>
                              </EuiStat>
                            </EuiFlexItem>
                            <EuiFlexItem>
                              <EuiStat
                                title={
                                  <EuiTextColor color="accent">
                                    <span style={{ fontSize: "1.4em" }}>
                                      {globalMetrics.survey_listened}%
                                    </span>
                                  </EuiTextColor>
                                }
                                textAlign="left"
                                isLoading={loading}
                                titleColor="accent"
                                description={
                                  <span style={{ fontSize: "1em" }}>
                                    Survey listened
                                  </span>
                                }
                              >
                                <div
                                  style={{
                                    display: "flex",
                                    fontSize: "1em",
                                  }}
                                >
                                  <EuiTextColor>
                                    {globalMetrics.survey_calls}% scored |
                                  </EuiTextColor>
                                  <EuiTextColor color="accent">
                                    &nbsp;{globalMetrics.survey_avg} AVG
                                  </EuiTextColor>
                                </div>
                              </EuiStat>
                            </EuiFlexItem>
                          </EuiFlexGroup>
                        </EuiPanel>
                      </EuiFlexItem>
                    </EuiFlexGroup>

                    <EuiSpacer />
                  </div>
                )}

                <EuiFlexGroup>
                  <EuiFlexItem grow={3}>
                    <EuiFlexGrid columns={4} gutterSize={"s"}>
                      {agents.map((agent, i) => {
                        const borderColor =
                          statusColors[agent.status] || "#d3d3d3";
                        const agentName = agent.name.split(" ");
                        const displayName = `${agentName[0]} ${
                          agentName[1] ? agentName[1][0] + "." : ""
                        }`;
                        return (
                          <EuiFlexItem key={i}>
                            <EuiPanel>
                              <EuiFlexGroup
                                alignItems="center"
                                justifyContent="spaceBetween"
                                style={{ position: "relative" }}
                              >
                                <EuiFlexGroup
                                  alignItems="center"
                                  gutterSize="s"
                                >
                                  <EuiFlexItem grow={false}>
                                    {agent.paused === "1" ? (
                                      <EuiAvatar
                                        size="l"
                                        name={agent.name}
                                        imageUrl={
                                          "https://cdn-icons-png.flaticon.com/512/149/149127.png"
                                        }
                                        iconType="pause"
                                        color="subdued"
                                        iconColor="gray"
                                        style={{
                                          border: `5px solid #9C7E1C`,
                                        }}
                                      />
                                    ) : (
                                      <div
                                        style={{
                                          position: "relative",
                                          display: "inline-block",
                                        }}
                                      >
                                        <EuiAvatar
                                          size="l"
                                          name={agent.name}
                                          color="subdued"
                                          style={{
                                            border: `5px solid ${
                                              agent.status === "AfterCallWork"
                                                ? "purple"
                                                : borderColor
                                            }`,
                                            ...(agent.status ===
                                              "AfterCallWork" && {
                                              filter: "blur(3px)",
                                            }),
                                            ...(agent.status === "Ringing" && {
                                              animation:
                                                "call 1.5s ease infinite",
                                            }),
                                          }}
                                        />

                                        {agent.status === "AfterCallWork" && (
                                          <div
                                            style={{
                                              position: "absolute",
                                              top: "50%",
                                              left: "50%",
                                              width: "30%",
                                              height: "30%",
                                              background: "transparent",
                                              border:
                                                "3px solid rgba(128, 0, 128, 0.6)",
                                              borderTop:
                                                "3px solid rgba(255, 255, 255, 0.9)",
                                              borderRadius: "50%",
                                              transform:
                                                "translate(-50%, -50%)",
                                              animation:
                                                "spin 1s linear infinite",
                                            }}
                                          ></div>
                                        )}
                                      </div>
                                    )}
                                  </EuiFlexItem>
                                  <EuiFlexItem
                                    grow={true}
                                    style={{ minWidth: 0 }}
                                  >
                                    <EuiFlexGroup
                                      alignItems="center"
                                      justifyContent="spaceBetween"
                                    >
                                      <EuiFlexItem
                                        grow={true}
                                        style={{ minWidth: 0 }}
                                      >
                                        <EuiTitle
                                          size="m"
                                          style={{
                                            whiteSpace: "nowrap",
                                            overflow: "hidden",
                                            textOverflow: "ellipsis",
                                          }}
                                        >
                                          <h2>{displayName}</h2>
                                        </EuiTitle>
                                      </EuiFlexItem>
                                      <EuiFlexItem grow={false}>
                                        <div
                                          style={{
                                            display: "flex",
                                            gap: "2px",
                                            justifyContent: "space-between",
                                          }}
                                        >
                                          {agent.inCall && (
                                            <EuiPopover
                                              button={
                                                <EuiButtonIcon
                                                  iconType={FaHeadphones}
                                                  onClick={() =>
                                                    togglePopover(agent.name)
                                                  }
                                                  aria-label="Open spy options"
                                                  isLoading={
                                                    loadingSpyCall[agent.name]
                                                  }
                                                />
                                              }
                                              isOpen={isPopoverOpen[agent.name]}
                                              closePopover={() =>
                                                closePopover(agent.name)
                                              }
                                              anchorPosition="upRight"
                                            >
                                              <EuiContextMenu
                                                initialPanelId={0}
                                                panels={[
                                                  {
                                                    id: 0,
                                                    items: [
                                                      {
                                                        name: "Activa",
                                                        icon: <FaMicrophone />,
                                                        onClick: () =>
                                                          handleSpyCall(
                                                            agent,
                                                            "active"
                                                          ),
                                                      },
                                                      {
                                                        name: "Pasiva",
                                                        icon: (
                                                          <FaMicrophoneSlash />
                                                        ),
                                                        onClick: () =>
                                                          handleSpyCall(
                                                            agent,
                                                            "passive"
                                                          ),
                                                      },
                                                    ],
                                                  },
                                                ]}
                                              />
                                            </EuiPopover>
                                          )}
                                        </div>
                                      </EuiFlexItem>
                                    </EuiFlexGroup>
                                    <EuiText size="m">
                                      <p>
                                        {agent.status === "Ringing" ? (
                                          <span
                                            style={{
                                              display: "flex",
                                              alignItems: "center",
                                              textAlign: "start",
                                              gap: "4px",
                                            }}
                                          >
                                            <MdCall />|
                                            <ThreeDots
                                              height="30"
                                              width="30"
                                              radius="12"
                                              color="#9C7E1C"
                                              ariaLabel="three-dots-loading"
                                              wrapperStyle={{}}
                                              wrapperClassName=""
                                              visible={true}
                                            />
                                            |
                                            <ThreeDots
                                              height="30"
                                              width="30"
                                              radius="12"
                                              color="#9C7E1C"
                                              ariaLabel="three-dots-loading"
                                              wrapperStyle={{}}
                                              wrapperClassName=""
                                              visible={true}
                                            />
                                          </span>
                                        ) : (
                                          <>
                                            <AgentStatus
                                              status={agent.status}
                                              statusStartTime={
                                                agent.statusStartTime
                                              }
                                            ></AgentStatus>
                                          </>
                                        )}
                                      </p>
                                    </EuiText>
                                  </EuiFlexItem>
                                </EuiFlexGroup>
                              </EuiFlexGroup>
                            </EuiPanel>
                          </EuiFlexItem>
                        );
                      })}
                    </EuiFlexGrid>
                  </EuiFlexItem>
                  <EuiFlexItem grow={1}>
                    <EuiFlexGrid columns={1} gutterSize={"s"}>
                      {queues.map((queue, i) => {
                        return (
                          <EuiFlexItem key={i}>
                            <EuiPanel>
                              <EuiFlexGroup
                                alignItems="center"
                                justifyContent="spaceBetween"
                              >
                                <EuiFlexItem>
                                  <EuiTitle
                                    size="xs"
                                    style={{
                                      whiteSpace: "nowrap",
                                      overflow: "hidden",
                                      textOverflow: "ellipsis",
                                    }}
                                  >
                                    <h2>{queue.queue.slice(5)}</h2>
                                  </EuiTitle>
                                </EuiFlexItem>
                                {showQueueTags && (
                                  <EuiFlexItem grow={false}>
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      fill={queue.color}
                                      viewBox="0 0 24 24"
                                      strokeWidth={1.5}
                                      stroke="currentColor"
                                      style={{ width: "16px", height: "16px" }}
                                    >
                                      <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        d="M9.568 3H5.25A2.25 2.25 0 0 0 3 5.25v4.318c0 .597.237 1.17.659 1.591l9.581 9.581c.699.699 1.78.872 2.607.33a18.095 18.095 0 0 0 5.223-5.223c.542-.827.369-1.908-.33-2.607L11.16 3.66A2.25 2.25 0 0 0 9.568 3Z"
                                      />
                                      <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        d="M6 6h.008v.008H6V6Z"
                                      />
                                    </svg>
                                  </EuiFlexItem>
                                )}
                              </EuiFlexGroup>

                              <EuiSpacer size="xs" />
                              <EuiFlexGrid columns={2}>
                                <EuiFlexItem grow={true}>
                                  <div
                                    style={{
                                      display: "inline-block",
                                      width: "48px",
                                      height: "48px",
                                      borderRadius: "50%",
                                      border:
                                        parseInt(
                                          queue.calls_on_hold_quantity
                                        ) === 0
                                          ? "solid 3px #d3d3d3"
                                          : "solid 3px #8a6a0a",
                                      backgroundColor:
                                        parseInt(
                                          queue.calls_on_hold_quantity
                                        ) === 0
                                          ? "#F1F4FA"
                                          : "#f1d86f",
                                      animation:
                                        parseInt(
                                          queue.calls_on_hold_quantity
                                        ) === 0
                                          ? ""
                                          : "call 1.5s ease infinite",
                                      textAlign: "center",
                                      lineHeight: "42px",
                                    }}
                                  >
                                    <b>{queue.calls_on_hold_quantity}</b>
                                  </div>
                                  <EuiSpacer size="s" />
                                  <EuiText size="xs">
                                    Free agents {queue.agents_available}/
                                    {queue.agents_logged}
                                  </EuiText>
                                </EuiFlexItem>
                                <EuiFlexItem grow={false}>
                                  <EuiText size="xs" textAlign="right">
                                    Attended: {queue.attended_calls}
                                  </EuiText>
                                  <EuiText size="xs" textAlign="right">
                                    Non-attended: {queue.non_attended_calls}
                                  </EuiText>
                                  <EuiText size="xs" textAlign="right">
                                    AHT:{" "}
                                    {new Date(queue?.talk_time * 1000)
                                      .toISOString()
                                      .substring(14, 19)}
                                  </EuiText>
                                  <EuiText size="xs" textAlign="right">
                                    Hold time:{" "}
                                    {new Date(queue?.hold_time * 1000)
                                      .toISOString()
                                      .substring(14, 19)}
                                  </EuiText>
                                </EuiFlexItem>
                              </EuiFlexGrid>
                            </EuiPanel>
                          </EuiFlexItem>
                        );
                      })}
                    </EuiFlexGrid>
                  </EuiFlexItem>
                </EuiFlexGroup>
              </div>
            </FullScreen>
          )}
        </>
      )}
    </div>
  );
};

export default Realtime;
