import React from "react";
import { useTimer } from "react-timer-hook";

const Timer = ({ startTime }) => {
  const startDate = new Date(startTime); // Fecha de inicio del estado
  const now = new Date(); // Hora actual

  // Configurar el temporizador
  const { seconds, minutes, hours } = useTimer({
    expiryTimestamp: now,
    autoStart: true,
  });

  // Calcular tiempo transcurrido
  const elapsedTimeInSeconds = Math.floor((now - startDate) / 1000);
  const displayMinutes = Math.floor(elapsedTimeInSeconds / 60);
  const displaySeconds = elapsedTimeInSeconds % 60;

  return (
    <span>
      {String(displayMinutes).padStart(2, "0")}:
      {String(displaySeconds).padStart(2, "0")}
    </span>
  );
};

const AgentStatus = ({ status = "UNKNOWN", statusStartTime = 0 }) => {
  return (
    <>
      {status} | <Timer startTime={statusStartTime} />
    </>
  );
};

export default AgentStatus;
